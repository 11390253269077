import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Main      from '../../Main';
import LandingLayout    from '../LandingLayout';


const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})
const MainLayout = props => {
  return (
    <Main>    
      <LandingLayout {...props} />
    </Main>
  )
};

MainLayout.propTypes = { children: PropTypes.node };

export default withRouter(connect(mapStateToProps)(MainLayout))
