import React, { useReducer } from 'react';


import PropTypes from 'prop-types';
import AppContext from './context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from './helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';


/*
| Antd design them
*/
import { ConfigProvider, theme } from 'antd';

const antdLightConfig = {
  "token": {
    "colorPrimaryBg": "rgba(245, 245, 245, 0.07)"
  }
}

const antdDarkConfig = {
  "algorithm": theme.darkAlgorithm,
    "token": {
      "colorPrimaryBg": "rgba(245, 245, 245, 0.07)",
      "colorBgContainer": "#0B1727",
      "colorBorder": "#344050",
      "colorBorderSecondary": "#344050",
      "colorBgElevated": "#121E2D",
      "colorBgBase": "#ff0000",
      "colorBgLayout": "#0B1727",
      "colorBgSpotlight": "#497cff"
    }
}

const Main = props => {
  const configState = {
    isFluid:        getItemFromStore('isFluid', settings.isFluid),
    isRTL:          getItemFromStore('isRTL', settings.isRTL),
    isDark:         getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency:               settings.currency,
    showBurgerMenu:         settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed:  false,
    showNavbarSecondary:    settings.showNavbarSecondary,
    NavbarSecondaryContent: settings.NavbarSecondaryContent,
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
          'showNavbarSecondary',
          'NavbarSecondaryContent'
        ].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig }}>
      <ConfigProvider
          theme={(config.isDark ? antdDarkConfig : antdLightConfig)}
      >
        {props.children}
      </ConfigProvider>
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
