import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'

import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from './redux/reducers'
import sagas    from './redux/sagas'
import Localization from './localization'
import Router       from './router'

const history 			    = createBrowserHistory()
const sagaMiddleware 	  = createSagaMiddleware()
const routeMiddleware 	= routerMiddleware(history)
const middlewares 		  = [sagaMiddleware, routeMiddleware]

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
    <Localization>
      <Router basename={process.env.PUBLIC_URL}  history={history} />
    </Localization>
    </Provider>
);

export { store, history }
