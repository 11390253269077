import React, { Fragment } from 'react'


import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
// import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
//import PublicLayout from './Public'
//import AuthLayout   from './Auth'
import MainLayout   from './Main'


import { history } from '../index'




const Layouts = {
  // public:  PublicLayout,
  // policy: PublicLayout,
  // auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    // if (pathname === '/') {
    //   return 'public'
    // }
    // if (/^\/auth(?=\/|$)/i.test(pathname)) {
    //   return 'auth'
    // }
    // if (/^\/public(?=\/|$)/i.test(pathname)) {
    //   return 'public'
    // }       
    // if (/^\/policy(?=\/|$)/i.test(pathname)) {
    //   return 'policy'
    // }       
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized  = user.authorized
  const isUserLoading     = user.loading
  const isAuthLayout      = getLayout() === 'auth'
 
  
  const BootstrappedLayout = () => {

    // if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
    //   return null
    // }

    // if(isAuthLayout && isUserAuthorized){
    //   // console.log('Redirect to="/dashboard');
    //   return <Redirect to="/dashboard" />
    // }      

    // if (!isAuthLayout && !isUserAuthorized) {
      
    //   if(getLayout() === 'public' || getLayout() === 'policy')
    //   {
    //     // return null
    //   } else {
    //     return <Redirect to="/auth/login" />
    //   }
    // } 

    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      {BootstrappedLayout()} 
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
