export const version              = '1.0.0';
export const navbarBreakPoint     = 'xl'; 
export const topNavbarBreakpoint  = 'lg';

export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
};

const exportConfig = {version, navbarBreakPoint, topNavbarBreakpoint, settings };

export default  exportConfig ;
