import React, { Fragment ,useContext,useState, useEffect, useRef } from 'react';
import classNames                 from 'classnames';
import { Link, useHistory   } from 'react-router-dom';

import PropTypes                  from 'prop-types';
import { connect } from 'react-redux'
import store from 'store'
import { useLocation } from 'react-router';
import { map } from 'lodash';
/*
| UI
*/
import { Menu } from 'antd'
import { Row, Col, Navbar, Nav, Button, Offcanvas } from 'react-bootstrap';
// import { Collapse, Navbar, NavItem, Nav } from 'reactstrap';
import Flex from '../../common/Flex';

/*
| ICONS
*/
import { PiListBulletsDuotone, PiListBold } from "react-icons/pi";

/*
| PAGES
*/

// import TopNavRightSideNavItem from '../../TopNavRightSideNavItem'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.role,
})
const NavbarTop = ({
  menuData = [],
  menuColor,
  logo,
  role,
}) => {


  const { pathname } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  useEffect(() => {
    // applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  // const applySelectedKeys = () => {
  //   const flattenItems = (items, key) =>
  //     items.reduce((flattenedItems, item) => {
  //       flattenedItems.push(item)
  //       if (Array.isArray(item[key])) {
  //         return flattenedItems.concat(flattenItems(item[key], key))
  //       }
  //       return flattenedItems
  //     }, [])
  //   const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
  //   setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  // }

  // const handleClick = e => {
  //   store.set('app.menu.selectedKeys', [e.key])
  //   setSelectedKeys([e.key])
  // }



  return (
    <div
      className={classNames(`p-0 m-0 top-navbar`, {
      })}
    >

      <Flex className="px-3">
        <div className="flex-grow-1"></div>
        {/* <div className=""><TopNavRightSideNavItem /></div> */}
      </Flex>
    </div>
  )
};

export default connect(mapStateToProps)(NavbarTop)
