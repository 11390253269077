import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
/*
| UI
*/

import {Card, Col, Row, Container, Image} from "react-bootstrap";

/*
| PAGES
*/

import Section      from './Section';
import Background from './Background';

/*
| ICONS
*/
import { GoChevronUp } from "react-icons/go";

export default class Footer extends Component {

    static defaultProps = {
        type : 'svg'
    }

    constructor(props){

        super(props);
        this.state = {
            type : this.props.type

        }

    }


    componentDidMount(){

    }

    getImage = () => {


    }

    render(){

        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          };

          const FooterTitle = ({ children }) => (
            <h5 className="text-uppercase  opacity-85 mb-3">{children}</h5>
          );
          
          const FooterList = ({ list }) => (
            <ul className="list-unstyled">
              {list.map(({ title, to }, index) => (
                <li className="mb-1" key={index}>
                  <a className="text-600" href={to}>
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          ); 

          
const menuList1 = [
    { title: 'Accounting software Login', to: 'https://dashboard.varexe.com/' },
    { title: 'Accounting software register', to: 'https://dashboard.varexe.com/auth/register/' },
    { title: 'Website Developments', to: 'https://varexe.com/web-developments/services/website-developments' },
    { title: 'Dedicated server hosting', to: 'https://varexe.com/web-developments/services/dedicated-server-hosting' },
    { title: 'Coding Solutions', to: 'https://varexe.com/web-developments/services/personalized-programming' },
  ];
        return(
            <Fragment>

                <Section bg="light" className="pt-8 pb-4 light text-start">
                        {/* <div
                        className="position-absolute btn-back-to-top cursor-pointer bg-dark"
                        onClick={scrollToTop}
                        >
                            <GoChevronUp className='fs-2' style={{color: '#FFF', transform: 'rotate(45deg)'}}  />
    
                        </div> */}
                        <Row>
                        <Col lg={4}>
                            <FooterTitle>Appatch Mission</FooterTitle>
                            <p className="text-600">
                            ✨ Appatch crafted by <a href="http://fouadassadi.com/" target='_blank'>Fouad</a>, a Developer by Habit: I make it a habit to provide tools that resonate with the daily challenges faced by developers. Your success fuels my passion for creating solutions that matter.
                            </p>
                            {/* <IconGroup className="mt-4" icons={bgWhiteIcons} /> */}
                        </Col>
                        <Col className="ps-lg-6 ps-xl-8">
                            <h6>Usefull Links</h6>
                            <FooterList list={menuList1}></FooterList>
                        
                        </Col>
                        </Row>
                    </Section>

                    <section className=" bg-dark py-0 text-center fs--1 light">
                        <hr className="my-0 border-600 opacity-25" />
                        <div className="container py-3">
                        <Row className="justify-content-between">
                            <Col xs={12} sm="auto">
                            <p className="mb-0 text-600">
                                Appacth {' '}
                                <span className="d-none d-sm-inline-block">| </span>
                                <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
                                <a
                                className="text-white opacity-85"
                                href="https://varexe.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                Varexe
                                </a>
                            </p>
                            </Col>
                            <Col xs={12} sm="auto">
                            {/* <p className="mb-0 text-600">v{version}</p> */}
                            </Col>
                        </Row>
                        </div>
                    </section>



                
            </Fragment>
        )
    }

}