import React, { Fragment ,useContext,useState, useEffect, useRef } from 'react';
import { Link, useHistory   } from 'react-router-dom';
import store from 'store'
/*
|UI
*/
import { Divider } from 'antd';

/*
|ICONS
*/

import { PiCalendarBlankDuotone  } from "react-icons/pi";

/*
|PAGES
*/
// import IconImage from 'components/common/icon/IconImage';
// import VarexeLogo from 'components/common/VarexeLogo';

export const renderItems = (label ='', key = '', url= '', icon = '') => {
  return {
      label: <Link className='text-decoration-none' to={url}>{label}</Link>,
      key: key,
      icon: icon,
    }
}

export const renderGroupLabel = (label ='') => {
  return <Divider className='m-0' orientation="left" orientationMargin="0" plain>{label}</Divider>
}

export const contactRoutes = renderItems(
    'Contact',
    'contact',
    '/contact',
    <PiCalendarBlankDuotone    className='fs-2 text-success'/>
)




export default  function getMenuData() {

  let menuItems = [];

  menuItems.push(contactRoutes);




    return menuItems
  }